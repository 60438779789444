/// <reference lib="webworker" />
/* eslint-disable no-restricted-globals */

const urlsToCache = [
    '', // find a way to inject files here (during build?)
];

const CACHE_NAME = 'parkingabo-cache';

self.addEventListener('install', (e: ExtendableEvent) => {
    e.waitUntil(
        caches.open(CACHE_NAME).then(cache => {
            cache.addAll(urlsToCache);
        }),
    );
});

self.addEventListener('fetch', (e: FetchEvent) => {
    e.respondWith(
        caches.match(e.request).then(res => {
            // res is the cached asset
            if (res) {
                return res;
            }

            return fetch(e.request).then(remoteRes => {
                if (
                    !remoteRes ||
                    remoteRes.status >= 400 ||
                    remoteRes.type !== 'basic'
                ) {
                    return remoteRes;
                }

                // here some logic can be done like updating the cache (SWR)

                return remoteRes;
            });
        }),
    );
});

self.addEventListener('activate', () => {
    // triggered onnew versions of the service worker
    // (code here executes after this version of the service worker is
    // responsible for the caching and the old one is no longer used)
    // do cleanup (like delete old cache if the name changed)
});

// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', event => {
    // use this to receive communication with the webpage
});

// Any other custom service worker logic can go here.
